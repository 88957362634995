import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

let details = {};

export function Detail() {
  const { id } = useParams();
  const [data, setData] = useState({});

  function fetchData(id) {
    fetch(`/api/miniprogram/adjust/school/detail?id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        details[id] = data.data;
        setData(data.data);
      })
      .catch(console.log);
  }

  useEffect(() => {
    if (details[id] === undefined) {
      fetchData(id);
      return;
    }

    setData(details[id]);
  }, [id]);

  useEffect(() => {
    window.onscroll = null;
  }, []);

  return (
    <div className="container">
      <Link to="/">{"<返回"}</Link>
      <h1>{data.title}</h1>
      <h2 className="specialty specialty-highlight">{data.speciality}</h2>
      <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
    </div>
  );
}
