import { useState, useEffect, useRef } from "react";
import "./App.css";
import { Link } from "react-router-dom";

// https://m.chinakaoyan.com/api/miniprogram/adjust/school/list?page=1&pagesize=10&area=&school=&speciality=&year=

let AppScrollY = 0;
let cacheList = [];
let page = 1;

function App() {
  const [list, setList] = useState([]);
  const requesting = useRef(false);

  function fetchData(page) {
    requesting.current = true;
    fetch(
      `/api/miniprogram/adjust/school/list?page=${page}&pagesize=50&area=&school=&speciality=&year=`
    )
      .then((response) => response.json())
      .then((data) => {
        let list = data.data || [];
        setList((last) => {
          const next = [...last, ...list];
          cacheList = next;
          return next;
        });
      })
      .catch(console.log)
      .finally(() => {
        requesting.current = false;
      });
  }

  useEffect(() => {
    if (cacheList.length === 0) {
      fetchData(page);
      return;
    }
    setList(cacheList);
  }, []);

  useEffect(() => {
    window.onscroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        if (requesting.current) {
          return;
        }
        page += 1;
        fetchData(page);
      }

      AppScrollY = window.scrollY;
    };

    return () => {
      window.onscroll = null;
    };
  }, []);

  useEffect(() => {
    if (window.scrollY < AppScrollY) {
      window.scrollTo(0, AppScrollY);
    }
  });

  return (
    <div className="container">
      <ul>
        {list.map((data) => (
          <li key={data.id}>
            <Link to={`/detail/${data.id}`}>
              <p className="title">{data.title}</p>
              <p
                className={`specialty ${
                  data.speciality.includes("地理") && "specialty-highlight"
                }`}
              >
                {data.speciality}
              </p>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default App;
